




import { defineComponent } from "@vue/composition-api";

import TaskView from "@/app/components/Task/TaskView.vue";

export default defineComponent({
  name: "ProjectTasksPage",
  components: { TaskView },
});
